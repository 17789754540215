import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

const careerInList = ({ role, locations, tags, link, bgColor, filterTags }) => {
    return (
        <div className='col-md-12 col-12 careerItem'>
            <div
                className={`card border-light-gray mb-3 ${
                    bgColor === 'white' ? 'bg-white' : 'footer-color'
                } `}>
                <div className='card-body'>
                    <h3 className='f-24 primary-text mb-3'>
                        {' '}
                        {role} - {locations}{' '}
                    </h3>
                    <div className='d-flex flex-wrap flex-column flex-sm-row'>
                        {tags.map((tag) => {
                            return (
                                <button
                                    className='text-white text-uppercase mr-3 bg-new-gray br-21 px-3 py-1-5 mt-3 border-0'
                                    value={tag}
                                    onClick={(e) => filterTags(e)}
                                    key={uuidv4()}>
                                    {tag}
                                </button>
                            )
                        })}
                    </div>
                    <table className='w-100 mt-3'>
                        <tbody>
                            <tr>
                                <td className='w-50'>
                                    <h4 className='career-job-location'>{locations}</h4>
                                </td>
                                <td className='text-right'>
                                    <div className='roll-button'>
                                        <a
                                            className='px-5 bg-light-blue text-white mobile-padding-left mobile-padding-right'
                                            href={link}>
                                            <span data-hover='Learn more'>Learn more</span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default careerInList

careerInList.propTypes = {
    role: PropTypes.string,
    locations: PropTypes.string,
    tags: PropTypes.array,
    link: PropTypes.string,
    bgColor: PropTypes.string,
    filterTags: PropTypes.func,
}
