import React, { useState, Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import { v4 as uuidv4 } from 'uuid'

import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import Spacing from '../components/partials/Spacing'

import CareerInList from '../components/careers/CareerInList'

interface StateProps {
  activeChoiceData: CareersState
}
interface CareersState {
  tags: Array<string>
  careerItems: Array<CareerInList>
}

const initialCareerItems = [
  {
    role: 'Technical Support Engineer',
    locations: 'Raleigh, NC',
    tags: ['Engineering'],
    link: '/careers/technical-support-engineer/',
  },
  {
    role: 'Streaming Cloud Kafka SRE (Lead)',
    locations: 'New York, London, Munich, Athens, Madrid',
    tags: ['Engineering'],
    link: '/careers/streaming-cloud-kafka-sre/',
  },
  {
    role: 'Sales Engineer',
    locations: 'London, UK',
    tags: ['Engineering', 'Sales'],
    link: '/careers/sales-engineer',
  },
  {
    role: 'Sales Engineer',
    locations: 'New York, NY',
    tags: ['Engineering', 'Sales'],
    link: '/careers/sales-engineer/?location=us',
  },
  {
    role: 'Senior Frontend Engineer',
    locations: 'London, Munich, Athens, Madrid',
    tags: ['Engineering'],
    link: '/careers/frontend-engineer',
  },
  {
    role: 'UX Architect',
    locations: 'Athens, GR',
    tags: ['Engineering'],
    link: '/careers/ux-architect/?location=gr',
  },
  {
    role: 'UX Architect',
    locations: 'London, UK',
    tags: ['Engineering'],
    link: '/careers/ux-architect/',
  },
  {
    role: 'UX Designer',
    locations: 'Athens, GR',
    tags: ['Engineering'],
    link: '/careers/ux-designer/?location=gr',
  },
  {
    role: 'UX Designer',
    locations: 'London, UK',
    tags: ['Engineering'],
    link: '/careers/ux-designer/',
  },
  {
    role: 'Technical Writer',
    locations: 'New York, NY',
    tags: ['Marketing'],
    link: '/careers/technical-writer/?location=us',
  },
  {
    role: 'Technical Writer',
    locations: 'London, UK',
    tags: ['Marketing'],
    link: '/careers/technical-writer/',
  },
  {
    role: 'Technical Product Manager',
    locations: 'London, Munich, Athens, Madrid',
    tags: ['Engineering'],
    link: '/careers/technical-product-manager/',
  },
  {
    role: 'Strategic Account Executive',
    locations: 'London, UK',
    tags: ['Sales'],
    link: '/careers/strategic-account-executive/',
  },
  {
    role: 'Strategic Account Executive',
    locations: 'New York, NY',
    tags: ['Sales'],
    link: '/careers/strategic-account-executive/?location=us',
  },
  {
    role: 'Enterprise Account Executive ',
    locations: 'London, UK',
    tags: ['Sales'],
    link: '/careers/enterprise-account-executive/',
  },
  {
    role: 'Enterprise Account Executive ',
    locations: 'New York, NY',
    tags: ['Sales'],
    link: '/careers/enterprise-account-executive/?location=us',
  },
  {
    role: 'Business Development Representative ',
    locations: 'New York, NY',
    tags: ['Marketing'],
    link: '/careers/business-development-representative/?location=us',
  },
  {
    role: 'Developer Advocate',
    locations: 'London, UK | Europe',
    tags: ['Marketing'],
    link: '/careers/developer-advocate/',
  },
  {
    role: 'Developer Advocate',
    locations: 'New York, NY',
    tags: ['Marketing'],
    link: '/careers/developer-advocate/?location=us',
  },
  {
    role: 'Senior Scala Engineer',
    locations: 'London, Munich, Athens, Madrid',
    tags: ['Engineering'],
    link: '/careers/senior-scala-engineer/',
  },
  {
    role: 'SRE / DevOps',
    locations: 'Europe',
    tags: ['Engineering'],
    link: '/careers/sre-devops/',
  },
  {
    role: 'Product Marketing Manager',
    locations: 'North America',
    tags: ['Marketing'],
    link: '/careers/product-marketing-manager/',
  },
]

const careers: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query CompanySEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Company_Careers" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const Scroll = () => {
    document
      .querySelector('#vacancies')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const [activeChoiceData, setActiveChoiceData] = useState<CareersState>({
    tags: ['Engineering', 'Marketing', 'Sales', 'Business', 'All'],
    careerItems: initialCareerItems,
  })

  const { tags, careerItems } = activeChoiceData
  const filterTags = (e) => {
    Scroll()
    e.target.blur()
    if (e.target.value === 'All') {
      setActiveChoiceData({
        ...activeChoiceData,
        careerItems: initialCareerItems,
      })
    } else {
      let filteredItems = initialCareerItems.filter((careerItem) => {
        return careerItem.tags.includes(e.target.value)
      })

      setActiveChoiceData({ ...activeChoiceData, careerItems: filteredItems })
    }
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="mt-5 pt-3 pb-3 bg-light-blue">
        <div className="container-1 pb-5 pt-4 ipad-margin-top">
          <div className="row mt-5">
            <div className="col-12 col-md-8 offset-md-2 text-center pt-0">
              <h3 className="text-white p-3">Join the Lenses.io team</h3>
              <h1 className="text-white pb-4" style={{ fontWeight: 'bold' }}>
                Where data is the protagonist
              </h1>
              <p className="text-white f-40">&#8595;</p>
              <br />
              <div className="roll-button">
                <a className="text-white career-see-roles" onClick={Scroll}>
                  <span data-hover="See open roles">See open roles</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="text-center pt-5 pb-5 px-2">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mb-4">
                <p className="title career-title">OUR MISSION</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <p className="career-h5">
                We believe that every business should empower all employees with
                data and applications democracy and act with data ethics.
              </p>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mb-4">
                <p className="title career-title">OUR VISION</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <p className="career-h5">
                Our vision is making real time data available to everyone and as
                easy to use as the email or phone.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="text-center pt-5 pb-5 px-2">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mb-5">
                <StaticImage
                  className="text-center w-25 mx-auto"
                  src="../resources/images/drive/how-we-work.png"
                  placeholder="tracedSVG"
                  alt="How we work"
                />
                <p className="title career-title pt-3">HOW WE WORK</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <p className="career-h5">
                A distributed team of passionate people of all age and ethnicity
                in a high growth DataOps company where people are our asset. We
                are customer first, not afraid of challenges, excel and innovate
                in our fields.
                <br />
                <br />
                We strive to live with our values every day: transparency,
                collaboration, care and high standards.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="text-center pt-5 pb-5 px-2">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mb-5">
                <p className="title career-title">HEADQUARTERS</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="my-img-container">
                    <StaticImage
                      src="../resources/images/about/acropolis.jpg"
                      placeholder="tracedSVG"
                      alt="Athens, GR company HQ"
                      className="w-100 my-image ui-images"
                    />
                  </div>
                  <br />
                  Akropoli, Lempesi 5-7
                  <br />
                  Athens, GR
                  <br />
                  <a
                    className="link-text"
                    href="https://www.google.com/maps/place/Lempesi+5-7,+Athina+117+42,+Greece/@37.9678858,23.7276299,17"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    map
                  </a>
                </div>
                <div className="col-md-6">
                  <div className="my-img-container">
                    <StaticImage
                      src="../resources/images/about/wall-street.jpg"
                      placeholder="tracedSVG"
                      alt="New York, 110 Wall St, 10005"
                      className="w-100 my-image ui-images"
                    />
                  </div>
                  <br />
                  110 Wall St, 10005
                  <br />
                  New York, USA
                  <br />
                  <a
                    className="link-text"
                    href="https://www.google.com/maps/place/110+Wall+St,+New+York,+NY+10005,+USA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    map
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="open mb-2" />

      <Spacing />
      <div id="vacancies" className="mb-3"></div>

      <section className="text-center pt-5 pb-5 px-2" id="openpositions">
        <div className="container-1">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 text-center">
              <div className="heading-title mb-4">
                <p className="title career-title">OPEN POSITIONS</p>
              </div>

              <p className="fw-200 f-18">
                To join our team, even if you do not see a position open, do not
                hesitate to
                <a href="mailto:people@lenses.io"> send your resume</a>. Your
                <a
                  href="https://assets.ctfassets.net/tnuaj0t7r912/6xWucQShzt1Nw6v5qQwXNJ/2141c0cc33cbf7cec01f10ca51802ff1/Lenses.io_Canditate_Privacy_Notice.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  data privacy{' '}
                  <i
                    className="fa fa-external-link"
                    aria-hidden="true"
                    style={{ fontSize: '10px' }}
                  ></i>
                </a>{' '}
                comes first.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
              <h2 className="h1-like cera-pro text-center">Vacancies</h2>
            </div>
            <div className="d-flex flex-wrap pt-5">
              <div className="col-12 mb-5">
                {tags.map((tag) => {
                  return (
                    <button
                      key={uuidv4()}
                      className="text-white cursor-pointer text-uppercase mr-3 bg-new-gray br-21 px-3 py-2 mt-3 border-0"
                      value={tag}
                      onClick={(e) => filterTags(e)}
                    >
                      {tag}
                    </button>
                  )
                })}
              </div>
              {careerItems.map((item, index) => {
                return (
                  <Fragment key={uuidv4()}>
                    <CareerInList
                      role={item.role}
                      locations={item.locations}
                      tags={item.tags}
                      link={item.link}
                      bgColor={index % 2 === 1 ? 'white' : 'light-gray'}
                      filterTags={filterTags}
                    />
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-4">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
              <h2 className="h1-like cera-pro text-center">Be social</h2>
            </div>
            <div className="col-12 mb-4">
              <div className="d-flex flex-wrap justify-content-center">
                <a
                  title="Slack"
                  className="f-28 mx-2 link-dark"
                  href="https://launchpass.com/lensesio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-slack f-19 " aria-hidden="true"></i>
                </a>
                <a
                  title="Twitter"
                  className="f-28 mx-2 link-dark"
                  href="https://twitter.com/lensesio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter f-19 p-0" aria-hidden="true"></i>
                </a>
                <a
                  title="Youtube"
                  className="f-28 mx-2 link-dark"
                  href="https://www.youtube.com/c/lensesio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-youtube-play f-19 p-0"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  title="Linkedin"
                  className="f-28 mx-2 link-dark"
                  href="https://linkedin.com/company/lensesio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-linkedin f-19 p-0 pb-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}
export default careers
